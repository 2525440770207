import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: {},
  },
  reducers: {
    setUser(state, action) {
      const payload = action.payload
      return {
        ...state,
        payload,
      }
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
})

export const { setUser, setLoading } = authSlice.actions

export default authSlice.reducer
