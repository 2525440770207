import { createSlice } from "@reduxjs/toolkit"

const carneSlice = createSlice({
  name: "carne",
  initialState: {
    value: {},
  },
  reducers: {
    setInfosCarne(state, action) {
      const payload = action.payload
      return {
        ...state,
        label: payload?.label,
        type: payload?.type,
        date: payload?.date,
        month: payload?.month,
        id: payload?.id
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
})

export const { setInfosCarne, setLoading } = carneSlice.actions

export default carneSlice.reducer
